.characteristic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.characteristic_img {
    flex: 1;
    margin-right: 20px;
}

.characteristic_text {
    flex: 2;
}

.characteristic_text p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.characteristic_text_title {
    font-size: 18px;
    font-weight: bold;
}

.characteristic_text_subtitle {
    font-weight: bold;
}

.tender-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.read-more-button {
    background-color: #b6cd2e;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #45a049;
}
/* Tenderpack.css */

.tenderpack-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Півпрозорий чорний фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Висока з-index, щоб вікно було поверх іншого контенту */
}

.tenderpack-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.tenderpack-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #555;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Півпрозорий чорний колір */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Збільште значення z-index, якщо потрібно */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .tenderpack {
    margin-top: 20px;
  }
  
  .equipment-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .equipment-table th, .equipment-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .equipment-table th {
    background-color: #f2f2f2;
  }
  
  .equipment-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #333;
    padding: 8px 12px;
    border-radius: 5px;
  }
  
  .close-button:hover {
    background-color: #555;
  }
  