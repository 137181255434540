@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");

:root {
  --main-color: #ffac3c;
  --text-color: #fff;
  --font-open-sans: "Open Sans", sans-serif;
  --font-oswald: "Oswald", sans-serif;
  --bs-gutter-x: 0.75rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: white;
  font-family: var(--font-open-sans);
  font-weight: 300;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0px;
  background-color: var(--main-color);
  padding: 10px 20px;
}

.logo h1 {
  padding: 3px 15px;
  font-weight: 700;
  font-family: var(--font-oswald);
}

.logo h1 a {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
}

.logo h1 a img {
  width: 70px;
  margin-right: 5px;
}

.buttons ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
}

.buttons ul li {
  margin-right: 10px;
  background-color: var(--main-color);
}

.buttons ul li p {
  border: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.buttons ul li p:hover,
.buttons ul li p.active {
  color: #fff;
  background-color: green;
}

.nav {
  position: absolute;
  border: solid 1px black;
  margin: 53px 0 0 0;
}

.nav ul {
  display: flex;
  flex-direction: column;
  background-color: var(--main-color);
}

.nav ul li {
  display: flex;
  background-color: var(--main-color);
  padding: 10px;
}

.nav ul li:hover {
  color: green;
  transform: translateX(5px);
  transition: transform 0.3s;
}

.nav ul li a {
  align-self: center;
  background-color: var(--main-color);
  font-size: 20px;
}

.nav ul li svg {
  align-self: center;
  font-size: 20px;
}

.main_banner .banner {
  display: flex;
  align-items: center;
  background-image: url(./img/slide-home.jpg);
  background-size: cover;
  height: 900px;
}

.main_banner .banner .container {
  max-width: 1500px;
  padding: 0 var(--bs-gutter-x);
  margin: 0 auto;
}

.text_right {
  text-align: center !important;
}

.main_banner .banner .container .text_right h1 {
  color: var(--text-color);
  text-align: center !important;
  margin: 0 0 400px 0;
}

.main_banner .banner img {
  width: 100%;
}
.mission_img {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.current-photo {
  max-width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out, transform 0.3s ease; /* Добавляем анимацию для увеличения */
  border: 1px solid transparent; /* Добавляем начальную прозрачную рамку */
}

.current-photo:hover {
  transform: scale(1.05); /* Увеличиваем размер при наведении */
  border-color: #ffac3c; /* Изменяем цвет рамки при наведении */
}

.slide-button {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Добавляем анимацию для изменения цвета */
}

.slide-button:hover {
  background-color: rgba(255, 204, 60, 0.95); /* Желтый цвет при наведении */
  color: #333; /* Цвет текста */
}

.left-button {
  position: absolute;
  left: 20px;
}

.right-button {
  position: absolute;
  right: 20px;
}

.container {
  position: relative; /* Чтобы установить положение для заголовка */
  text-align: center; /* Выравниваем текст по центру */
}









.characteristic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  padding: 0 40px; 
}

.characteristic_text {
  font-size: 20px;
  font-weight: 400;
  width: 50%;
  margin-right: 20px; 
}

.characteristic_text_title {
  font-size: 50px;
  font-weight: 700;
}

.characteristic_img {
  width: 50%;
  padding: 0 40px; 
}

.characteristic_img img {
  width: 100%;
  height: auto;
}



.values {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./img/bg.png);
  background-size: cover;
  width: 100%;
  height: 820px;
}

.values_text {
  width: 400px;
  padding: 20px;
  margin-right: 10%;
  margin-left: auto;
  text-align: center;
}
.values_text h2 {
  font-size: 48px;
  font-weight: 700;
  color: var(--text-color);
}

.values_text hr {
  border: solid 2px var(--text-color);
  margin: 20px 0;
}

.values_text p {
  font-size: 24px;
  color: var(--text-color);
  font-weight: 400;
}

.history {
  background-color: #f8f9fa;
  padding: 50px 0;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.history .display-2 {
  font-size: 2.5em;
  color: black;
}

.history-carousel {
  margin-top: 10px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.history-carousel .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  overflow: auto;
}

.history-carousel .col-6 {
  flex: 0 0 calc(30% - 20px);
  margin-bottom: 20px;
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  margin: 0 1px;
}

.history-carousel .col-6:hover {
  transform: scale(1.05);
}

.history-carousel img {
  max-width: 50%;
  max-height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.history-carousel p {
  margin: 10px 0;
  color: #333;
}

.history-carousel .lead {
  font-size: 1.2em;
  font-weight: bold;
}

.down {
  background-color: #ffac3c;
  color: #1a1a1a;
  padding: 30px 0;
  font-family: var(--font-open-sans);
}

.down-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.down-section {
  flex: 1;
  margin: 0 15px;
  background-color: #ffac3c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.down-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #1a1a1a;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 8px;
}

ul li a {
  text-decoration: none;
  color: #1a1a1a;
  font-size: 0.9rem;
  transition: color 0.3s;
}

ul li a:hover {
  color: #fff;
  background-color: #1a1a1a;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.down-bottom {
  text-align: center;
  margin-top: 20px;
}

.down-bottom p {
  font-size: 0.8rem;
  color: #1a1a1a;
}
.certificate_text {
  text-align: center;
  margin-top: 20px;
}

.certificate_text h2 {
  font-size: 34px;
  color: #333;
}

.certificate_horizontal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 100px 0;
}

.certificate_image {
  max-width: 20%;
  width: 100%;
  height: auto;
  border: 4px solid var(--main-color);
  border-radius: 10px;
  transition: transform 0.3s ease, border 0.3s ease;
}

.certificate_image:first-child {
  margin-right: 20px;
}

.certificate_image:last-child {
  margin-left: 20px;
}

.certificate_image:hover {
  transform: scale(1.07);
  border-color: #ffac3c;
}
.product-item {
  display: flex;
  position: relative; 
  padding-left: 20px;
  cursor: pointer; 
  padding-left: 30px;
  font-size: 23px; 

}
.product-item .arrow {
  position: absolute;
  left: 0; 
  transition: color 0.3s;
  opacity: 0; 
}

.product-item:hover .arrow {
  opacity: 1;
}

.product-item:hover {
  color: #ffac3c;
}

.history_text .down_text ul {
  padding-left: 10px; 
}
.history_text {
  margin-right: 40px;
  margin-left: 40px;
    font-size: 34px;
}
.flex {
  display: flex;
}

.image {
  margin-right: 60px;
}

.image img {
  max-width: 100%; 
  height: auto; 
}


.certificate_text {
  text-align: center;
  margin-top: 20px;
}

.certificate_text h2 {
  font-size: 34px;
  color: #333;
} 

.history {
  padding: 100px 0;
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 1500px;
}

.flex {
  display: flex;
  justify-content: center;
}

.history_img {
  width: 100%;
}

.history .container .flex {
  display: flex;
  justify-content: space-evenly;
}

.history .container .flex .history_text .top_text {
  text-align: left;
  font-size: 48px;
  font-weight: 700;
  padding: 10px 0 0 0;
  font-family: var(--font-open-sans);
  line-height: 1;
}

.history .container .flex .history_text .top_text small {
  font-size: 50%;
  color: #74ab36;
  line-height: 1.6;
  font-weight: 700;
  font-family: var(--font-open-sans);
}

.history .container .flex .history_text .down_text {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  font-family: var(--font-open-sans);
}
.photo-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.vertical-photo {
  margin-left: auto;
  max-width: 100%; 
  max-height: 700px; 
}

.horizontal-photo {
  width: 100%; 
  height: auto; 
}

.photo-row img {
  width: 30%; 
  height: auto; 
  border-radius: 5px;
}

.photo-row .height_photo img {
  height: 100%; 
}

.photo-row img:hover {
  transform: scale(1.1);
}
@media (max-width: 2565px) {
  .main_banner .banner {
    height: 1000px;
  }

  .buttons ul li p {
    font-size: 20px;
  }

  .logo h1 a {
    font-size: 50px;
  }

  .logo h1 a img {
    width: 125px;
  }

  .main_banner .banner .container .text_right h1 p {
    font-size: 100px;
  }

  .main_banner .indicators {
    padding: 50px;
  }

  .certificate_horizontal {
    height: 1000px;
  }

  .history .container {
    max-width: 2000px;
  }

  .product-item {
    font-size: 40px;
  }

  .top_text p {
    font-size: 60px;
  }
}

@media (max-width: 1445px) {
  .certificate_horizontal {
    height: 600px;
  }

  .product-item{
    text-align: start;
  }
}

@media (max-width: 1030px) {
  .buttons ul li p {
    font-size: 13px;
  }

  .logo h1 a {
    font-size: 20px;
  }

  .logo h1 a img {
    width: 50px;
  }

  .indicator {
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .certificate_horizontal {
    height: 400px;
  }

  .history .container {
    max-width: 900px;
  }

  .top_text p {
    font-size: 30px;
  }

  .product-item{
    font-size: 15px;
    padding: 0;
    text-align: start;
  }

  .top_text p {
    font-size: 40px;
  }
}
@media (max-width: 880px) {
  .characteristic_text_title {
    font-size: 30px!important;
    font-weight: 700;
}
}
@media (max-width: 770px) {
  .buttons ul li p {
    font-size: 11px;
  }

  .logo h1 a {
    font-size: 20px;
  }

  .logo h1 a img {
    width: 50px;
  }

  .indicator {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .characteristic_text_title {
    font-size: 30px !important;
    font-weight: 700;
  }

  .characteristic_text {
    font-size: 15px;
    font-weight: 400;
    width: 50%;
    margin-right: 20px;
  }

  .main_banner .banner .container .text_right h1 p {
    font-size: 70px;
  }
}
  @media (max-width: 635px) {
    .main_banner .banner .container .text_right h1 {
      color: var(--text-color);
      text-align: center !important;
      margin: 0 0 200px 0;
    }

    .characteristic {
      display: flex;
      margin: 100px 0;
      padding: 0 40px;
      flex-wrap: wrap-reverse;
    }

    .map-contacts{
      font-size: 15px !important;
    }

    .certificate_text h2 {
      font-size: 20px;
      color: #333;
    }

    .certificate_horizontal {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0;
    }

    .current-photo {
      height: 70%;
      transition: opacity 0.5s ease-in-out, transform 0.3s ease;
      border: 1px solid transparent;
    }

    .characteristic_img {
      width: 100%;
      padding: 30px 0;
    
    }

    .characteristic_text {
      font-size: 15px;
      font-weight: 400;
      width: 100%;
      margin-right: 20px;
    }

    .characteristic_text_title {
      font-size: 20px !important;
      font-weight: 700;
    }

    .image{
      margin: 0;
    }

    .history .container .flex {
      display: flex;
      flex-wrap: wrap;
    }

    .main_banner .banner .container .text_right h1 p {
      font-size: 50px;
    }

    .main_banner .banner {
      height: 700px;
    }

    body {
      font-size: 10px;
    }

    .logo h1 a {
      font-size: 18px;
    }

    .logo h1 a img {
      width: 30px;
    }

    .buttons {
      display: flex;
    }

    .buttons ul li p {
      font-size: 10px;
      padding: 1px 0px;
    }
    
    .nav {
      display: block;
    }

    .nav ul {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 10px;
      width: 100%;
    }

    .nav ul li {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      transition: transform 0.3s;
    }

    .nav ul li:hover {
      color: green;
      transform: translateX(5px);
    }

    .nav ul li a {
      align-self: center;
      font-size: 20px;
    }

    .nav ul li svg {
      align-self: center;
      font-size: 20px;
    }
  }

@media (max-width: 420px) {
  .current-photo {
    height: 60%;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease;
    border: 1px solid transparent;
    
  } 
  .map-wrapper
  {
    flex-wrap: wrap;
  }

  .buttons{
    display: none;
  }

  .product-item {
    font-size: 13px;
    padding: 0;
    text-align: start;
  }

  .top_text p {
    font-size: 20px;
  }

  header {
    display: flex;
    top: 0px;
    background-color: var(--main-color);
    padding: 10px 20px;
    justify-content: center;
  }

  .main_banner .banner .container .text_right h1 p {
    font-size: 30px;
  }
}


@media (max-width: 325px) {
  .map-contacts {
    font-size: 13px !important;
  }

  .map-contacts h2{
    font-size: 20px !important;
  }

  .main_banner .banner {
    height: 500px;
  }
  
  .current-photo {
    height: 40%;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease;
    border: 1px solid transparent;
  }
}


  .slide-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .left-button {
    left: 0;
  }
  
  .right-button {
    right: 0;
  }
  .certificate_slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .slide-button {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Добавляем анимацию для изменения цвета */
  }
  
  .slide-button:hover {
    background-color: rgba(255, 204, 60, 0.95); /* Желтый цвет при наведении */
    color: #333; /* Цвет текста */
  }
  
  .left-button {
    position: absolute;
    left: 20px;
  }
  
  .right-button {
    position: absolute;
    right: 20px;
  }